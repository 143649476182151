import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { App, Service } from '@kfd/core';
import { CFG_CONTEXT_SERVICE, CfgContextService } from '@kfd/cfg-core';
import { ConfigService, ENDPOINT_SERVICE_TOKEN, WEB_CONFIG_SERVICE, WebCoreModule } from '@kfd/web-core';
import { environment } from '../environments/environment';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import Material from '@primeng/themes/material';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: Material,
        options: {
          //remove to enable dark mode by default
          darkModeSelector: '.my-app-dark',
        },
      },
    }),
    provideClientHydration(),
    provideRouter(appRoutes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    {
      provide: WEB_CONFIG_SERVICE,
      useFactory: () => {
        const configService = new ConfigService();
        configService.services = {
          [Service.AUTH_SERVICE]: environment.services.auth,
          [Service.CFG_SERVICE]: environment.services.cfg,
          [Service.CONTENT_SERVICE]: environment.services.content,
        };
        configService.apps = {
          [App.CMS]: environment.apps.cms,
          [App.WEB]: environment.apps.web,
        };
        return configService;
      },
    },
    importProvidersFrom(BrowserAnimationsModule, HttpClientModule, WebCoreModule),
    {
      provide: ENDPOINT_SERVICE_TOKEN,
      useValue: {
        services: {
          [Service.CFG_SERVICE]: environment.services.cfg,
        },
      },
    },
    // context service is required for all modules to provide project id
    {
      provide: CFG_CONTEXT_SERVICE,
      useClass: CfgContextService,
    },
  ],
};
